#people-list {
  list-style: none;
  padding-left: 0;
}

#search-result-list {
  margin-top: 1em;
  padding-left: 1em;
}

#search-result-list li {
  list-style: none;
  padding-left: 0;
}

#main {
  width: 100%;
  max-width: 40vw;
}

#chamberHeadline {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 1em;
}

#chamberHeadline h2 {
  padding-left: 0;
}

button {
  cursor: pointer;
}
