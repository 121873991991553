body {
  background-color: #242E23;
  color: #E4E9E4;
  font-family: "Arimo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.app {
  padding: 0em; /* 1em works for youtube but then too much padding */
}
